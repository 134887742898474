import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import addMinutes from 'date-fns/addMinutes'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
// import MuiLink from '@material-ui/core/Link'
import { withSnackbar } from 'notistack'

import { parseError } from '../helpers'

const timeZoneOffset = new Date().getTimezoneOffset()

const RecallStatusDialog = ({
  recallId,
  recallTitle,
  isOpen,
  handleClose,
  enqueueSnackbar,
}) => {
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    if (!isOpen) return // run on dialog open

    let didCancel = false

    const fetchRecallStatus = async () => {
      try {
        setLoading(true)
        setError(false)
        const response = await axios.get(`/getRecallStatus/${recallId}`)
        // console.log(response.data)
        if (didCancel) return
        setStatuses(response.data)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (didCancel) return
        setLoading(false)
      }
    }

    fetchRecallStatus()

    return () => {
      didCancel = true
    }
  }, [isOpen, recallId])

  // const handleRestart = async () => {
  //   try {
  //     await axios.post('/postPopulateDailyRecallItems', {
  //       recallId,
  //       title: recallTitle,
  //       username: sessionStorage.getItem('stiletto.username'),
  //     })
  //     // console.log(response)
  //     enqueueSnackbar('Restarted', { variant: 'success' })
  //   } catch (e) {
  //     // console.log(e)
  //     enqueueSnackbar('Unable to restart', { variant: 'error' })
  //   }
  // }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth scroll="paper">
      <DialogTitle>
        Recall Status{recallTitle && `: ${recallTitle}`}
      </DialogTitle>
      <DialogContent>
        {isLoading && <CircularProgress />}
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
        {statuses.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statuses.map((s, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {format(
                      addMinutes(parseISO(s.timestamp), timeZoneOffset),
                      'dd-MM-yyyy HH:mm:ss:SSS'
                    )}
                  </TableCell>
                  <TableCell>{s.username}</TableCell>
                  <TableCell>
                    {s.status}
                    {/* {s.status.startsWith('writing to DailyRecall_Items') && (
                      <Typography variant="caption">
                        {' '}
                        <MuiLink as="button" onClick={handleRestart}>
                          stuck?
                        </MuiLink>
                      </Typography>
                    )} */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

RecallStatusDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  recallId: PropTypes.string.isRequired,
  recallTitle: PropTypes.string,
}
RecallStatusDialog.defaultProps = {
  recallTitle: '',
}

export default withSnackbar(RecallStatusDialog)
