import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withRouter } from 'react-router-dom'

import { issueRecallAction } from '../../../redux/reducers/recall/actions'

const IssueRecallDialog = ({ isOpen, handleClose, history }) => {
  const dispatch = useDispatch()
  const [isTest, setTest] = useState(true)
  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState(false)
  const [description, setDescription] = useState('')

  const isFirstUpdate = useRef(true)

  useEffect(() => {
    // Don't run on first render
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false
      return
    }

    let isError = false
    if (title.trim().length < 3) {
      isError = true
    }
    setTitleError(isError)
  }, [title])

  const handleSubmit = () => {
    if (titleError) return
    setTitleError(false)
    dispatch(issueRecallAction(title, description, isTest, history))
    handleClose()
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle id="form-dialog-title">Issue Recall</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          error={titleError}
          helperText="Minimum three characters"
          fullWidth
          id="recall-title"
          label="Title"
          margin="dense"
          onChange={e => setTitle(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleSubmit()
            }
          }}
          value={title}
        />
        <Box mt={1}>
          <TextField
            multiline
            fullWidth
            id="recall-title"
            label="Description"
            margin="dense"
            onChange={e => setDescription(e.target.value)}
            value={description}
          />
        </Box>
        <Box mt={1}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTest}
                  onChange={e => setTest(e.target.checked)}
                  value="isTest"
                />
              }
              label="Is a test recall"
            />
            <FormHelperText>
              Test v3 recalls will not write to RMIS.dbo.DailyRecall_Items and
              will therefore not be picked up by the in-store Sabot tablet
              application.
            </FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="primary"
          disabled={title.trim().length < 3}
          onClick={handleSubmit}
          variant="contained"
        >
          Issue Recall
        </Button>
      </DialogActions>
    </Dialog>
  )
}

IssueRecallDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired // from react-router, issueRecallAction needs this
}

export default withRouter(IssueRecallDialog)
