import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { yellow } from '@material-ui/core/colors'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import formatDate from 'date-fns/format'
import isValidDate from 'date-fns/isValid'
import { convertMilliseconds } from '../../helpers'

import InfoButton from '../InfoButton'
import ReportTitle from './ReportTitle'
import CSVDownloadLink from './CSVDownloadLink'

const DATE_FORMAT = 'dd-MM-yyyy HH:mm'

const useStyles = makeStyles(theme => ({
  highlight: {
    backgroundColor: yellow[50],
    color:
      theme.palette.type === 'dark' ? theme.palette.primary.contrastText : null
  }
}))

const ReportHeader = ({
  cumulativeWeeks,
  executionTimeMs,
  foundRecallUnits,
  findStockData,
  hideReportTitle,
  minModifiedDate,
  maxModifiedDate,
  sortValue,
  unitsToRecall
}) => {
  const classes = useStyles()

  let sortValueText

  switch (sortValue) {
    case 'last-weeks-profit':
      sortValueText = 'last weeks profit'
      break
    case 'last-weeks-cover':
      sortValueText = 'last weeks cover'
      break
    case 'last-weeks-sales':
      sortValueText = 'last weeks sales units'
      break
    case 'cumulative-profit':
      sortValueText = `${cumulativeWeeks} week profit`
      break
    case 'cumulative-cover':
      sortValueText = `${cumulativeWeeks} week cover`
      break
    case 'cumulative-sales':
      sortValueText = `${cumulativeWeeks} week sales units`
      break
    default:
      sortValueText = sortValue
  }

  const formattedMinDate = isValidDate(minModifiedDate) ? formatDate(minModifiedDate, DATE_FORMAT) : null
  const formattedMaxDate = isValidDate(maxModifiedDate) ? formatDate(maxModifiedDate, DATE_FORMAT) : null

  return (
    <>
      {!hideReportTitle && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ReportTitle />
          <CSVDownloadLink findStockData={findStockData} />
        </Box>
      )}
      <Box mb={2}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Box>
            {sortValue && (
              <Typography gutterBottom>
                Sorted by <span className={classes.highlight}>{sortValueText}</span>
                .
              </Typography>
            )}
            {(foundRecallUnits || foundRecallUnits === 0) && (
              <Typography gutterBottom>
                {unitsToRecall} units requested. {foundRecallUnits} units found.
                <InfoButton
                  tooltipContent={
                    <Typography variant="body2">
                      Units found could be a few more than requested as the system analyses SKU by SKU.
                      As soon as units found is greater than requested, it will stop analysing further SKUs.
                    </Typography>
                  }
                />
              </Typography>
            )}
            {(formattedMinDate || formattedMaxDate) && (
              <Typography variant="body2" gutterBottom>
                Product_Availability data updated: {formattedMinDate}
                {formattedMaxDate !== formattedMinDate && ` - ${formattedMaxDate}`}
              </Typography>
            )}
            {executionTimeMs && (
              <Typography variant="body2">
                Time to analyse SKUs: {convertMilliseconds(executionTimeMs)}
              </Typography>
            )}
          </Box>
          {hideReportTitle && (
            <CSVDownloadLink findStockData={findStockData} />
          )}
        </Box>
      </Box>
    </>
  )
}

ReportHeader.propTypes = {
  cumulativeWeeks: PropTypes.number,
  executionTimeMs: PropTypes.number,
  findStockData: PropTypes.arrayOf(PropTypes.object).isRequired,
  foundRecallUnits: PropTypes.number,
  hideReportTitle: PropTypes.bool,
  minModifiedDate: PropTypes.object,
  maxModifiedDate: PropTypes.object,
  sortValue: PropTypes.string,
  unitsToRecall: PropTypes.number
}
ReportHeader.defaultProps = {
  cumulativeWeeks: null,
  executionTimeMs: null,
  hideReportTitle: false,
  foundRecallUnits: null,
  minModifiedDate: null,
  maxModifiedDate: null,
  sortValue: null,
  unitsToRecall: null
}

export default ReportHeader
