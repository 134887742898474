import axios from 'axios'

import { parseError } from '../../../helpers'

const INITIAL_STATE = {
  error: false,
  isLoaded: false,
  items: [],
}

const FETCH_SEASONS = 'FETCH_SEASONS'
const POPULATE_SEASONS = 'POPULATE_SEASONS'
const SET_SEASONS_ERROR = 'SET_SEASONS_ERROR'

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SEASONS:
      return {
        ...state,
        items: [],
        isLoaded: false,
      }
    case POPULATE_SEASONS:
      return {
        ...state,
        items: action.payload,
        isLoaded: true,
      }
    case SET_SEASONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoaded: true,
      }
    default:
      return state
  }
}

export const fetchSeasonsAction = () => async dispatch => {
  try {
    dispatch({ type: SET_SEASONS_ERROR, payload: false })
    dispatch({ type: FETCH_SEASONS })
    const response = await axios.get('/getSeasons')
    dispatch({
      type: POPULATE_SEASONS,
      payload: makeReducedSeasonsList(response.data),
    })
  } catch (e) {
    dispatch({
      type: SET_SEASONS_ERROR,
      payload: parseError(`fetchSeasonsAction - ${parseError(e)}`),
    })
  }
}

export const makeReducedSeasonsList = (items) => {
  const sortedItems = items.sort((a, b) => {
    const yearA = parseInt(a.season_year.split(' ').pop())
    const yearB = parseInt(b.season_year.split(' ').pop())
    return yearB - yearA
  })

  const autumnWinter = sortedItems.filter(item => item.season_year.toLowerCase().includes('autumn winter')).slice(0, 3)

  const continuity = sortedItems.find(item => item.season_year.toLowerCase() === 'continuity')

  const springSummer = sortedItems.filter(item => item.season_year.toLowerCase().includes('spring summer')).slice(0, 3)

  return [...autumnWinter,  ...springSummer, continuity].filter(Boolean)
}


export default reducer
