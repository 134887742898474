import React, { useState } from 'react'
import PropTypes from 'prop-types'
import addMinutes from 'date-fns/addMinutes'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import { Link } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

import InfoButton from '../../InfoButton'
import CopyCriteriaButton from '../../CopyCriteriaButton'
import ExportCriteriaButton from '../../ExportCriteriaButton'
import RecallStatusDialog from '../../RecallStatusDialog'

const timeZoneOffset = new Date().getTimezoneOffset()

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: 'pointer',
    display: 'inline-block',
    width: '1.2em',
  },
}))

const RecallTableRow = React.memo(
  ({ rowData: r }) => {
    const classes = useStyles()
    const [isStatusDialogOpen, setStatusDialogOpen] = useState(false)

    const handleStatusDialogOpen = () => setStatusDialogOpen(true)
    const handleStatusDialogClose = () => setStatusDialogOpen(false)

    const formattedIssuedDate = r.issuedDate
      ? format(
          addMinutes(parseISO(r.issuedDate), timeZoneOffset),
          'dd-MM-yyyy HH:mm'
        )
      : null

    let parsedFilters
    let sortValue
    try {
      parsedFilters = JSON.parse(r.filtersJSON)
      sortValue = parsedFilters.sortValue
    } catch (e) {
      parsedFilters = {}
      sortValue = ''
    }

    return (
      <>
        <TableRow>
          <TableCell>{r.isTest && <Icon className="fa fa-check" />}</TableCell>
          <TableCell>
            <Typography variant="body2">
              <MuiLink component={Link} to={`/recall/${r.recallId}`}>
                {r.title || r.recallId}
              </MuiLink>
            </Typography>
          </TableCell>
          <TableCell>
            {r.description && (
              <InfoButton
                iconComponent={
                  <Icon className={`fa fa-comments ${classes.icon}`} />
                }
                tooltipContent={<Typography>{r.description}</Typography>}
              />
            )}
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              {formattedIssuedDate || '[no data]'}
            </Typography>
          </TableCell>
          <TableCell>{sortValue}</TableCell>
          <TableCell>
            <Box display="flex" alignItems="center">
              <Typography variant="caption">
                {r.status}{' '}
                <MuiLink as="button" onClick={handleStatusDialogOpen}>
                  [more]
                </MuiLink>
              </Typography>
            </Box>
          </TableCell>
          <TableCell align="right">
            <Typography variant="body2">
              {r.unitsRecalled || '[no data]'}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="body2">
              {r.unitsReallocated || '[no data]'}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="body2">
              {r.unitsHomeShopping || '[no data]'}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="body2">
              {r.unitsWarehouse || '[no data]'}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Box display="flex">
              {r.filtersJSON && (
                <CopyCriteriaButton criteriaJSON={r.filtersJSON} hideText />
              )}
              {r.filtersJSON && (
                <ExportCriteriaButton criteriaJSON={r.filtersJSON} hideText />
              )}
            </Box>
          </TableCell>
        </TableRow>
        <RecallStatusDialog
          recallId={r.recallId}
          recallTitle={r.title}
          isOpen={isStatusDialogOpen}
          handleClose={handleStatusDialogClose}
        />
      </>
    )
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps.rowData, nextProps.rowData)) return true
    return false
  }
)

RecallTableRow.displayName = 'RecallTableRow'
// RecallTableRow.whyDidYouRender = true

RecallTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
}

export default RecallTableRow
