import React, { useEffect, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import MuiLink from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import { lightBlue, indigo } from '@material-ui/core/colors'
import isEqual from 'lodash/isEqual'

import {
  setActiveRecallIdAction,
  setActiveScreenAction
} from '../../redux/reducers/global/actions'
import Page404 from '../Page404'
import Screen from '../Screen'
import InfoButton from '../InfoButton'
import NoPaddingContainer from '../NoPaddingContainer'
import RecallsTab from './RecallsTab'
import ReallocationsTab from './ReallocationsTab'
import FindStockTab from './FindStockTab'
import CopyCriteriaButton from '../CopyCriteriaButton'
import ExportCriteriaButton from '../ExportCriteriaButton'
import { parseError } from '../../helpers'
import RecallStatusDialog from '../RecallStatusDialog'
// import ConfirmWriteDialog from './ConfirmWriteDialog'
import EditableText from '../EditableText'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles(theme => {
  // console.log(theme)
  return {
    invert: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      display: 'inline-block',
      padding: theme.spacing(1),

      '& a': {
        color: theme.palette.type === 'dark' ? indigo[500] : lightBlue[100]
      }
    },
    paper: {
      overflow: 'scroll',
      padding: theme.spacing(4)
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    }
  }
})

// const timeZoneOffset = new Date().getTimezoneOffset()

const RecallDetailScreen = React.memo(
  ({
    enqueueSnackbar,
    match: {
      params: { id: recallId }
    }
  }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [isLoadingRecall, setIsLoadingRecall] = useState(true)
    const [recall, setRecall] = useState(null)
    const [error, setError] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [isStatusDialogOpen, setStatusDialogOpen] = useState(false)

    const handleStatusDialogOpen = useCallback(
      () => setStatusDialogOpen(true),
      []
    )
    const handleStatusDialogClose = useCallback(
      () => setStatusDialogOpen(false),
      []
    )

    useEffect(() => {
      dispatch(setActiveScreenAction(null))
      dispatch(setActiveRecallIdAction(recallId))
    }, [dispatch, recallId])

    useEffect(() => {
      if (!isLoadingRecall) return
      let didCancel = false

      const fetchRecall = async () => {
        try {
          if (didCancel) return
          setIsLoadingRecall(true)
          const response = await axios.get(`/getRecall/${recallId}`)
          // console.log(response.data)
          // console.log(JSON.parse(response.data.findStockDataJSON))
          if (didCancel) return
          if (response.data.length === 0) {
            throw new Error(`Recall ${recallId} not found.`)
          }
          if (didCancel) return
          setRecall(response.data)
        } catch (e) {
          // console.log(e)
          if (didCancel) return
          setError(parseError(e))
        } finally {
          if (didCancel) return
          setIsLoadingRecall(false)
        }
      }

      fetchRecall()

      return () => {
        didCancel = true
      }
    }, [recallId, isLoadingRecall])

    const updateRecall = useCallback(
      async recallObj => {
        try {
          // console.log(recallObj)
          await axios.post('/postUpdateRecall', {
            username: sessionStorage.getItem('stiletto.username'),
            recallId,
            recallObj
          })
          setIsLoadingRecall(true)
        } catch (e) {
          throw new Error(e)
        }
      },
      [recallId]
    )

    const handleSaveDescription = useCallback(
      newDescription => {
        updateRecall({ description: newDescription })
      },
      [updateRecall]
    )
    const handleSaveTitle = useCallback(
      newTitle => {
        updateRecall({ title: newTitle })
      },
      [updateRecall]
    )

    const handleTabChange = useCallback((e, value) => setActiveTab(value), [])

    if (error) return <Page404 />

    if (isLoadingRecall)
      return (
        <Screen>
          <NoPaddingContainer maxWidth="md">
            <CircularProgress variant="indeterminate" />
          </NoPaddingContainer>
        </Screen>
      )

    const filters = recall.filtersJSON ? JSON.parse(recall.filtersJSON) : {}
    const formattedDate = recall.issuedDate
      ? format(parseISO(recall.issuedDate), 'dd-MM-yyyy HH:mm')
      : ''

    // console.log(recall)

    return (
      <Screen>
        <Container maxWidth="lg">
          {recall.isTest && (
            <Typography paragraph className={classes.invert}>
              This is a test recall.{' '}
              {/* <MuiLink as="button" onClick={handleWriteDialogOpen}>
              Write to DailyRecall_Items
            </MuiLink>
            . */}
              <InfoButton
                tooltipContent={
                  <Typography variant="body2">
                    Test v3 recalls do not write to RMIS.dbo.DailyRecall_Items
                    and will therefore not be picked up by the in-store Sabot
                    tablet application.
                  </Typography>
                }
              />
            </Typography>
          )}
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box>
              <Typography variant="h4" component="h2" gutterBottom>
                <EditableText
                  onSave={handleSaveTitle}
                  enqueueSnackbar={enqueueSnackbar}
                  initialValue={recall.title}
                  snackbarMessages={{
                    error: e =>
                      `Unable to update recall title: ${parseError(e)}`,
                    success: 'Recall title updated.'
                  }}
                />
              </Typography>
              <Typography gutterBottom>Issued: {formattedDate}</Typography>
              {recall.status && (
                <Typography gutterBottom>
                  Status: {recall.status}{' '}
                  <MuiLink as="button" onClick={handleStatusDialogOpen}>
                    [more]
                  </MuiLink>
                </Typography>
              )}
              <Typography component="span">
                <Box display="flex" alignItems="center">
                  <span style={{ marginRight: '0.25em' }}>Description:</span>
                  <EditableText
                    onSave={handleSaveDescription}
                    enqueueSnackbar={enqueueSnackbar}
                    initialValue={recall.description}
                    snackbarMessages={{
                      error: e =>
                        `Unable to update recall description: ${parseError(e)}`,
                      success: 'Recall description updated.'
                    }}
                  />
                </Box>
              </Typography>
            </Box>
            <Box alignSelf="flex-start">
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box display="flex">
                  {recall.filtersJSON && (
                    <CopyCriteriaButton
                      criteriaJSON={recall.filtersJSON}
                      hideText
                    />
                  )}
                  {recall.filtersJSON && (
                    <ExportCriteriaButton
                      criteriaJSON={recall.filtersJSON}
                      hideText
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Divider variant="middle" />

          <Box
            mb={2}
            mt={2}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Typography>Units recalled: {recall.unitsRecalled}</Typography>
            <Typography>
              Reallocated to branch: {recall.unitsReallocated}
            </Typography>
            <Typography>
              To Home Shopping: {recall.unitsHomeShopping}
            </Typography>
            <Typography>To warehouse: {recall.unitsWarehouse}</Typography>
          </Box>

          <Divider variant="middle" />

          <Box mt={2}>
            <AppBar position="static" color="secondary">
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Recalled" />
                <Tab label="Reallocated" />
                <Tab label="Ranked Stock" />
              </Tabs>
            </AppBar>

            {activeTab === 0 && (
              <Paper className={classes.paper}>
                <RecallsTab recallId={recallId} filters={filters} />
              </Paper>
            )}
            {activeTab === 1 && (
              <Paper className={classes.paper}>
                <ReallocationsTab recallId={recallId} filters={filters} />
              </Paper>
            )}
            {activeTab === 2 && (
              <Paper className={classes.paper}>
                <FindStockTab recall={recall} />
              </Paper>
            )}
          </Box>
        </Container>
        <RecallStatusDialog
          recallId={recall.recallId}
          recallTitle={recall.title}
          isOpen={isStatusDialogOpen}
          handleClose={handleStatusDialogClose}
        />
        {/* <ConfirmWriteDialog
        recall={recall}
        isOpen={isWriteDialogOpen}
        handleClose={handleWriteDialogClose}
        handleReload={() => setIsLoadingRecall(true)}
      /> */}
      </Screen>
    )
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps.match, nextProps.match)) return true
    return false
  }
)

RecallDetailScreen.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
  match: PropTypes.object.isRequired // from react-router
}

RecallDetailScreen.displayName = 'RecallDetailScreen'
// RecallDetailScreen.whyDidYouRender = true

export default withRouter(withSnackbar(RecallDetailScreen))
